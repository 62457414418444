 import CryptoJS from 'crypto-js';
var keyStr = "abcd66sxyzhkewjdsds12345"
    //加密
function encrypt(word) {
    var key = CryptoJS.enc.Utf8.parse(keyStr);
    var srcs = CryptoJS.enc.Utf8.parse(word);
    var encrypted = CryptoJS.AES.encrypt(srcs, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
}
//解密
function decrypt(word) {
    var key = CryptoJS.enc.Utf8.parse(keyStr);
    var decrypt = CryptoJS.AES.decrypt(word, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });
    return CryptoJS.enc.Utf8.stringify(decrypt).toString();
}


function isEmpty(data) {
    if (data == undefined || data == null || data === '') {
        return true;
    }
    return false
}

function isEmptyObj(obj) {
    for (var key in obj) {
        if (isEmpty(obj[key])) {
            return true;
        }
    }
    return false;
}

function isNotEmptyObj(obj) {
    return !isEmptyObj(obj);
}

function isNotEmpty(data) {
    return !isEmpty(data);
}

function getData(key) {
    let data = sessionStorage.getItem(key);
    if (isEmpty(data)) {
        return null;
    }
    return JSON.parse(data);
}

function setData(key, obj) {
    if (isEmpty(obj)) {
        obj = null;
    }
    obj = JSON.stringify(obj)
    sessionStorage.setItem(key, obj);
}

function delData(key) {
    sessionStorage.removeItem(key);
}

function dateFmt(fmt, date) {
    let ret;
    const opt = {
        "y+": date.getFullYear().toString(), // 年
        "M+": (date.getMonth() + 1).toString(), // 月
        "d+": date.getDate().toString(), // 日
        "H+": date.getHours().toString(), // 时
        "m+": date.getMinutes().toString(), // 分
        "s+": date.getSeconds().toString() // 秒
    };
    for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
            fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
        }
    }
    return fmt;
}

//判断打开的浏览器类型
function isAlipayOrWechat() {
    var userAgent = window.navigator.userAgent.toLowerCase();
    if (userAgent.match(/Alipay/i) == "alipay") {
        return "alipay";
    } else if (userAgent.match(/MicroMessenger/i) == "micromessenger") {
        return "wechat";
    } else {
        return "other";
    }
}


export default {
    isEmpty,
    isEmptyObj,
    isNotEmpty,
    isNotEmptyObj,
    dateFmt,
    getData,
    setData,
    delData,
    encrypt,
    decrypt,
    isAlipayOrWechat,
}